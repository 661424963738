<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"><i class="ficon" data-feather="bell"></i><span
      class="badge badge-pill badge-danger badge-up" *ngIf="unreadCount > 0">{{
      unreadCount
      }}</span></a>
  <ul ngbDropdownMenu aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificaciones</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ unreadCount }} Nuevas
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>

      <a class="d-flex" *ngFor="let message of notifications" (click)="markNoti(modalForm,message)">

        <div class="media d-flex align-items-start">
          <div class="media-left">
            <span [data-feather]="'bell'" style="font-size: 50px;"></span>
            <!-- <img src="../../../../../assets/images/icons/bell.png" alt="avatar" width="16" height="16" /> -->
          </div>
          <div class="media-body">
            <div class="row">
              <div class="col-10">
                <h4 class="media-heading" [innerHTML]="message.title"></h4>
              </div>

              <div class="col-2" *ngIf="!message.read">
                <div style="background-color: #125EAC; height: 10px; width: 10px; border-radius: 20px;"></div>
              </div>
            </div>

            <p class="notification-text">{{ message.description }}</p>
            <p style="float:right;">{{ message.time | date: 'MMMM d, h:mm:ss a' }}</p>
          </div>
        </div>

      </a>

    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <button class="btn btn-outline-primary btn-block" (click)="markAllAsRead()" style="border-radius: 30px;">Marcar todas como
        leídas</button>
      <button class="btn btn-primary btn-block" (click)="redirectToHistory()"
       style="border-radius: 30px;">Ver historial</button>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>

<ng-template #modalForm let-modal>
  <div class="modal-header">
    <button type="button" class="close" (click)="modal.dismiss('Cross click');" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="display: flex; justify-content: center;">
      <div class="row">
        <div class="col-12 text-center">
          <h3>{{propertyAct.title}}</h3>
        </div>
        <div class="col-12 text-center">
          <h5>Esta propiedad esta próxima a vencer<br>Acciones necesarias</h5>
        </div>

        <div class="col-12 text-center">
          <button type="button" class="btn btn-primary w-25" rippleEffect (click)="propertyNotiWhats()">
            <span [data-feather]="'message-circle'" [class]="'mr-50'"></span>Contactar via WhatsApp</button>
        </div>

        <div class="col-12 text-center mt-2">
          <button type="button" class="btn btn-primary w-25" rippleEffect (click)="propertyNotiAvilable()">
            <span [data-feather]="'clock'" [class]="'mr-50'"></span>Aumentar vigencia</button>
        </div>

        <div class="col-12 text-center mt-2 mb-2">
          <button type="button" class="btn btn-primary w-25" rippleEffect (click)="propertyNotiDisable()">
            <span [data-feather]="'x-circle'" [class]="'mr-50'"></span>Inhabilitar inmueble</button>
        </div>
      </div>
    </div>


  </div>

</ng-template>