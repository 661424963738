import { Role } from "./role";
import { Permission } from "../../modules/admin/users/permission.model";
import { Consultant } from "../../modules/admin/agentes/consultant.model";

export class User {
  constructor(user?: User) {
    this.id = null;
    this.email = "";
    this.phone = "";
    this.password = "";
    this.firstName = "";
    this.lastName1 = "";
    this.lastName2 = "";
    this.fullName = "";
    this.avatar = "";
    this.lada = user && user.lada ? user.lada : "";
  }
  id: number;
  email: string;
  phone: string;
  password: string;
  pass?: string;
  firstName: string;
  lastName1: string;
  lastName2: string;
  fullName: string;
  avatar: string;
  role?: Role;
  token?: string;
  permissions?: Permission[];
  realEstate_id?: number;
  agentId?: number;
  state?: number;
  province?: number;
  admin?: boolean;
  isAgent?: boolean;
  Agent?: Consultant;
  isWhatsApp?: boolean;
  lada?;
  lada1?;
  primaryColor?: string;
  secondaryColor?: string;
}
