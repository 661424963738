import { Injectable } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Product } from './products.model';

@Injectable({
  providedIn: 'root'
})
export class ProductsServiceService {

  constructor(private _server: ServerService) { }

  getProducts(): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Products').then(
        (resp: Product[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  /* getClientsByRE(idRE: number): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('indexClientsRE/' + idRE).then(
        (resp: Product[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  indexProducts(): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('indexClientes').then(
        (resp: Product[]) => {
          resolve(resp);
        }, (err: any) => {
          rejecterr);
        }
      );
    });
  } */

  getProduct(id: number): Promise<Product> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Products/' + id).then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  storeProduct(product: Product): Promise<Product> {
    return new Promise((resolve, reject) => {
      this._server.postServer('Products', product).then(
        (resp: Product) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  updateProduct(product: Product): Promise<Product> {
    return new Promise((resolve, reject) => {
      this._server.putServer('Products/' + product.id, product).then(
        (resp: Product) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  deleteProduct(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.deleteServer('Products/' + id).then(
        (resp: Product) => {
          resolve();
        }, (error: any) => {
          reject(error);
        }
      );
    })
  }

}
