import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentsService } from "app/modules/admin/payments/payments.service";
import { ProductsServiceService } from "app/modules/admin/products/products-service.service";
import { PropertyService } from "app/modules/admin/properties/property.service";
import { UserService } from "app/modules/admin/users/user.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class TraitInscriptionsSubscriptionsService {
  userLoggeado: any;

  constructor(
    private _router: Router,
    private _UserService: UserService,
    private _propertyService: PropertyService,
    private _paymentsService: PaymentsService,
    private _productsService: ProductsServiceService
  ) {}

  async getuserLoggeado() {
    this.userLoggeado = JSON.parse(localStorage.getItem("currentUser"))?.user;
  }

  ////////////////////////////////////////
  ///////////// INSCRIPCIONES ////////////
  ////////////////////////////////////////

  async checkInscripcionRealEstate() {
    await this.getuserLoggeado();
    if (this.userLoggeado) {
      await this._checkActiveInscriptions().then(async (resp: boolean) => {
        let activeInscriptions: boolean = resp;

        if (this.userLoggeado?.role == "Inmobiliaria" || this.userLoggeado?.role == "Agente") {
          await this._getRealState().then(async (resp) => {
            let real_estate: any = resp;

            await this.check10FreeDays(real_estate).then(async (resp) => {
              if (resp == false || resp == undefined) {
                await this._checkInscripcion().then(async () => {
                  let comproInscripcion: any = {};
                  comproInscripcion = JSON.parse(localStorage.getItem("comproInscripcion"));

                  if (this.checkRealEstateDisabled(real_estate)) {
                    Swal.fire(
                      "Inmobilairia deshabilitada",
                      "Tu inmobiliaria ha sido deshabilitada, por favor contacta al servicio técnico",
                      "info"
                    ).then(() => {
                      this._router.navigate(["/subscriptions"]);
                    });
                  }

                  if (activeInscriptions) {
                    const today = await this._getDateToday();
                    if (comproInscripcion.paid == 0 && real_estate.suscription_base.endDate < today) {
                      Swal.fire(
                        "No tienes una inscripción pagada..",
                        "Selecciona una de las inscripciones para continuar",
                        "info"
                      ).then(() => {
                        this._router.navigate(["/subscriptions"]);
                      });
                    }
                  }
                });
              }
            });
          });
        }
      });
    }
  }

  private async _checkActiveInscriptions() {
    return await this._productsService.getProduct(1).then(
      (product: any) => {
        let activeInscriptions = false;
        if (product?.data?.status) activeInscriptions = true;
        localStorage.setItem("activeInscriptions", JSON.stringify(activeInscriptions));
        return activeInscriptions;
      },
      (error: any) => {
        Swal.fire(
          "Error en el servidor CAI",
          "" +
            (error?.error?.errores
              ? error?.error?.errores
              : error?.error?.mensaje
              ? error?.error?.mensaje
              : error?.error?.text),
          "error"
        );
      }
    );
  }

  private async _checkInscripcion() {
    let comproInscripcion: any = {
      paid: 0,
      product_id: 0,
      concept: "No has pagado la inscripcion",
    };
    let params = {
      real_estate_id: this.userLoggeado.realEstate_id,
    };

    await this._paymentsService.getPaymentInscriptionRealEstate(params).then(
      (resp: any) => {
        if (resp.data.length == 0) {
          localStorage.setItem("comproInscripcion", JSON.stringify(comproInscripcion));
        } else {
          comproInscripcion.paid = resp.data.paid;
          comproInscripcion.concept = resp.data.concept;
          comproInscripcion.product_id = resp.data.product_id;
          localStorage.setItem("comproInscripcion", JSON.stringify(comproInscripcion));
        }
      },
      (error: any) => {
        localStorage.setItem("comproInscripcion", JSON.stringify(comproInscripcion));
        Swal.fire(
          error?.error?.errores ? error?.error?.errores : error?.error?.mensaje,
          "Realiza un pago de una inscripción para continuar",
          "error"
        ).then(() => {
          this._router.navigate(["/subscriptions"]);
        });
      }
    );
  }

  private async _getRealState() {
    return await this._UserService.getRealEstate().then(
      (data: any) => {
        return data;
      },
      (error: any) => {
        Swal.fire(
          "Error en el servidor GRS",
          "" +
            (error?.error?.errores
              ? error?.error?.errores
              : error?.error?.mensaje
              ? error?.error?.mensaje
              : error?.error?.text),
          "error"
        );
      }
    );
  }

  private async _getDateToday() {
    const current = new Date();
    let minDateToday = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };

    let year = minDateToday.year;
    let month = minDateToday.month <= 9 ? "0" + minDateToday.month : minDateToday.month;
    let day = minDateToday.day <= 9 ? "0" + minDateToday.day : minDateToday.day;
    return year + "-" + month + "-" + day;
  }

  async check10FreeDays(real_estate) {
    let tiene_prueba = false;
    const real_estate_created = real_estate.created_at.split("T")[0];
    const today = await this._getDateToday();

    if (real_estate_created == real_estate.suscription_base.startDate) {
      if (real_estate.suscription_base.endDate > today) {
        let comproInscripcion: any = {
          paid: 1,
          product_id: 0,
          concept: "Etapa de 10 dias de prueba",
        };
        localStorage.setItem("comproInscripcion", JSON.stringify(comproInscripcion));
        tiene_prueba = true;
        return tiene_prueba;
      } else {
        return tiene_prueba; //false
      }
    }

    if (real_estate_created < real_estate.suscription_base.startDate) {
      concept: "Ya pago al menos una mensualidad de suscripcion";
      return tiene_prueba; //false
    }

    if (real_estate_created > real_estate.suscription_base.startDate) {
      const fecha = new Date(real_estate_created);
      fecha.setDate(fecha.getDate() - 1);
      const nuevaFecha = fecha.toISOString().split("T")[0];
      if (nuevaFecha == real_estate.suscription_base.startDate) {
        let comproInscripcion: any = {
          paid: 1,
          product_id: 0,
          concept: "Etapa de 10 dias de prueba",
        };
        localStorage.setItem("comproInscripcion", JSON.stringify(comproInscripcion));
        tiene_prueba = true;
        return tiene_prueba;
      }
    }
  }

  checkRealEstateDisabled(real_estate) {
    if (real_estate.status == 3) {
      return true;
    } else {
      return false;
    }
  }

  ////////////////////////////////////////
  //////////// SUBSCRIPCIONES ////////////
  ////////////////////////////////////////

  async checkSusbscriptionRealEstate() {
    let comproInscripcion = JSON.parse(localStorage.getItem("comproInscripcion"));

    if (
      this.userLoggeado &&
      this.userLoggeado.role == "Inmobiliaria" /*  || this.this.userLoggeado.role == 'Super Administrador' */
    ) {
      if (comproInscripcion.paid) {
        return this._propertyService.realEstateSubscriptions(this.userLoggeado.realEstate_id).then(
          async (data: any) => {
            const today = await this._getDateToday();
            const endDateFree = data.subscriptionsRealEstate[0].endDate;

            if (
              (data.subscriptionBase == 0 && this.userLoggeado.role !== "Super Administrador") ||
              data.rememberBase ||
              data.rememberComplement
            ) {
              if (endDateFree < today) {
                Swal.fire(
                  "Suscripción caducada",
                  "Favor de revisar que su suscripción se haya pagado correctamente",
                  "error"
                ).then(() => {
                  if (data.rememberBase || data.subscriptionBase == 0) {
                    this._router.navigate(["/subscriptions"]);
                  }
                });
              }
            }
            return data;
          },
          (error: any) => {
            Swal.fire(
              "Error en el servidor RESS",
              "" +
                (error?.error?.errores
                  ? error?.error?.errores
                  : error?.error?.mensaje
                  ? error?.error?.mensaje
                  : error?.error?.text),
              "error"
            );
          }
        );
      }
    }
  }
}
