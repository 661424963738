import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { ThemeColorsService } from 'app/service/theme-colors.service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;
  constructor (
    public theme_service: ThemeColorsService,
  ){}
  
}
