/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  token: any;
  header: any;

  constructor(
    private http: HttpClient,
    private _router: Router,
  ) { }

  getToken() {
    this.token = localStorage.getItem('accessToken');
    this.header = new HttpHeaders({
      Authorization: 'Bearer ' + this.token
    });

  }


  getServer(url: string, params?) {
    return new Promise((resolve, reject) => {
      // if(params) {
        let http_params = new HttpParams({
          fromObject: params
       });
      // }
      // console.log('getServer PARAMS', http_params);
      this.http.get(environment.API_URL + url, { headers: this.header, params: http_params }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  postServer(url: string, params: any) {
    return new Promise((resolve, reject) => {
      console.log('POST SERVER PARAMS', params);
      this.http.post(environment.API_URL + url, params, { headers: this.header }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  putServer(url: string, params: any) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.API_URL + url, params, { headers: this.header }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  deleteServer(url: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.API_URL + url, { headers: this.header }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  getFile(url: string, params?) {
    this.header = new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let http_params = new HttpParams({
      fromObject: params
   });
    return new Promise((resolve, reject) => {
      this.http.get(environment.API_URL + url, { headers: this.header, params: http_params, responseType: 'blob', observe: 'response' }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  getFile2Post(url: string, params?) {
    this.header = new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    let http_params = new HttpParams({
      fromObject: params
   });
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + url, params, { headers: this.header, responseType: 'blob', observe: 'response' }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  getFileWithoutHeader(url: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.API_URL + url, { responseType: 'blob', observe: 'response' }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );
    });
  }

  postDataWithFileResponse(url: string, params) {
    this.header = new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    });
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + url, params, { headers: this.header, responseType: 'blob' }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  uploadFile(url: string, params: any) {
    this.header = new HttpHeaders({
      'enctype': 'multipart/form-data',
      Authorization: 'Bearer ' + this.token,
      'Access-Control-Allow-Origin': '*',
    });
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + url, params, { headers: this.header, }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  postFileData(url: string, params: any, file: File) {
    this.header = new HttpHeaders({
      'enctype': 'multipart/form-data',
      Authorization: 'Bearer ' + this.token,
      'Access-Control-Allow-Origin': '*',
    });
    const input = new FormData();
    input.append('file', file);
    input.append('data', JSON.stringify(params));
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + url, input, { headers: this.header, }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  postFileDataWithFileResponse(url: string, params: any, file: File) {
    this.header = new HttpHeaders({
      'enctype': 'multipart/form-data',
      Authorization: 'Bearer ' + this.token,
      'Access-Control-Allow-Origin': '*',
    });
    const input = new FormData();
    input.append('file', file);
    input.append('data', JSON.stringify(params));
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + url, input, { headers: this.header, responseType: 'blob' }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

  postFilesData(url: string, params: any, file: File[]) {
    this.header = new HttpHeaders({
      'enctype': 'multipart/form-data',
      Authorization: 'Bearer ' + this.token,
      'Access-Control-Allow-Origin': '*',
    });
    const input = new FormData();
    file.forEach(e => {
      input.append('file[]', e, e.name);
    });
    input.append('data', JSON.stringify(params));
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + url, input, { headers: this.header, }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          if (err.error.message === 'Unauthenticated.') {
            localStorage.removeItem('currentUser');
            this._router.navigate(['/login']);
          } else {
            reject(err);
          }
        }
      );
    });
  }

}
