import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServerService {
  constructor(private http: HttpClient) {}

  getServer(url: string, page?, params?) {
    return new Promise((resolve, reject) => {
      console.log("ENTRA MI PAGE LANDING?", page);
      let paramstosend;
      // params_busqueda = {...page, ...params_busqueda};
      /* if(!page) { page = 1 }
      if(!params) { page = {} } */
      // let paramstosend = Object.assign(page, params);
      // if(page || params) {
      //   console.log('existez?', page); /* page = {'page': page};  */paramstosend = Object.assign(params, page);
      // }
      // console.log("getServer PARAMS-PAGE", page, url);
      // console.log('PARAMETROS BUSQUEDDA GET', params_busqueda);

      // const stackTrace = new Error().stack;
      // console.log('stackTrace', stackTrace);
      console.log("PARAMEMTROSTOSEND LANDING DESDE", /* stackTrace */ paramstosend);

      // this.http.get(environment.API_URL_LANDING + url, { params: {'page': page/* , params: params_busqueda */} }).subscribe(
      this.http.get(environment.API_URL_LANDING + url, { params: page }).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  postServer(url: string, params: any) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL_LANDING + url, params).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getFile(url: string, params?) {
    var header = new HttpHeaders({
      "Content-type": "application/json",
    });
    let http_params = new HttpParams({
      fromObject: params,
    });
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.API_URL_LANDING + url, {
          headers: header,
          params: http_params,
          responseType: "blob",
          observe: "response",
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
