import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {

  token: any;
  header: any;
  baseUrl = 'https://api.mercadopago.com';

  constructor(private http: HttpClient,) { }

  // getToken() {
  //   this.header = new HttpHeaders({
  //     Authorization: 'Bearer ' + environment.ACCESS_TOKEN_MP
  //   });

  // }

  // getServer(url: string) {
  //   this.header = new HttpHeaders({
  //     Authorization: 'Bearer ' + environment.ACCESS_TOKEN_MP
  //   });
  //   return new Promise((resolve, reject) => {
  //     this.http.get(this.baseUrl + url, { headers: this.header }).subscribe(
  //       (res) => {
  //         resolve(res);
  //       }, (err) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  // postServer(url: string, params: any) {
  //   this.header = new HttpHeaders({
  //     Authorization: 'Bearer ' + environment.ACCESS_TOKEN_MP,
  //   });
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.baseUrl + url, params, { headers: this.header }).subscribe(
  //       (res) => {
  //         resolve(res);
  //       }, (err) => {
  //         console.log(err);
  //         reject(err);
  //       }
  //     );
  //   });
  // }
}
