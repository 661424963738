import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { ThemeColorsService } from 'app/service/theme-colors.service';

@Component({
  selector: '[core-menu-vertical-section]',
  templateUrl: './section.component.html'
})
export class CoreMenuVerticalSectionComponent {
  @Input()
  item: CoreMenuItem;
  constructor(private theme_service:ThemeColorsService)
  {}
}
