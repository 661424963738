import { Injectable } from "@angular/core";
import { MercadoPagoService } from "../services/mercado-pago.service";
import { ServerService } from "../services/server.service";
import { Subscription } from "./subscription.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private subscription: Subscription[];
  header;

  constructor(
    private http: HttpClient,
    private _server: ServerService,
    private _MercadoPagoService: MercadoPagoService
  ) {}

  getSubscriptions(): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      this.allSubscriptions().then(
        () => {
          const subscription: Subscription[] = JSON.parse(localStorage.getItem("Subscriptions"));
          resolve(subscription);
        },
        () => {
          const subscription: Subscription[] = [];
          reject(subscription);
        }
      );
    });
  }

  /* async getSubscription(id: number): Promise<Subscription> {
    this.subscription = JSON.parse(localStorage.getItem('subscription'));
    const subscriptionupdate: Subscription = this.subscription.find(subscription => subscription.id === id);
    return subscriptionupdate;
  } */

  getSubscription(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("Subscriptions/" + id).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }
  getSubscriptionRealEstate(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("getSubscriptionRealEstate/" + id).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  allSubscriptions(): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("Subscriptions").then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  allSubscriptionsWithTrash(): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("allSubscriptions").then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  subscriptionsRealEstates(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("subscriptionsRealEstates").then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  agregarSubscription(subscription: Subscription): Promise<Subscription> {
    return new Promise((resolve, reject) => {
      this._server.postServer("Subscriptions", subscription).then(
        (resp: any) => {
          this.subscription = JSON.parse(localStorage.getItem("Subscriptions"));
          this.subscription.push(resp);
          localStorage.setItem("Subscriptions", JSON.stringify(this.subscription));
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  confirmTransfer(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer(`confirmTransfer/${params.id}`, params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  pagosManuales(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("Payments", { all: 1, evidence: 1 }).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  // pagoManual(datosDePago:any): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._server.uploadFile('transfer', datosDePago).then(
  //       (resp: any) => {
  //         resolve(resp);
  //       }, (error: any) => {
  //         reject(error);
  //       }
  //     );
  //   });
  // }

  updateSubscriptions(subscriptions: Subscription[]): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      const variable = {
        subscriptions: subscriptions,
      };
      this._server.postServer("subscriptionsUpdate", variable).then(
        (resp: Subscription[]) => {
          this.subscription = resp;
          localStorage.setItem("Subscriptions", JSON.stringify(resp));
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  updateSubscription(data: any): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      this._server.putServer("Subscriptions/" + data.id, data).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  deleteSubscription(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.deleteServer("Subscriptions/" + id).then(
        (resp: Subscription) => {
          this.subscription = JSON.parse(localStorage.getItem("Subscriptions"));
          const index = this.subscription.findIndex((x) => x.id === id);
          this.subscription.splice(index, 1);
          localStorage.setItem("Subscriptions", JSON.stringify(this.subscription));
          resolve();
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  realEstateSubscriptions(id: number): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("getSubscriptionsRealEstate/" + id).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  payCart(params: any, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("payCart/" + id, params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getPreferencesMPPaginaWeb(preference: any, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("subscriptionPaginaWebRealEstate/" + id, preference).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getPreferencesMPpago(preference: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("createPayment", preference).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  cancelRealEstateSubscription(id: number, type: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("cancel-real-estate-subscription", { real_estate_id: id, subscription_id: type }).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  cancelaSuscripcionPaginaWeb(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.deleteServer("cancelSubscriptionPaginaWebRealEstate/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  compraComplementSubscription(id: number, params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("buyComplementSubscriptionRealEstate/" + id, params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  activeSubscriptionPaginaWeb(id: number, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("activePaginaWebSubscriptionRE/" + id, params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  activeComplementsCart(id: number, params: any): Promise<any> {
    console.log(`Ruta: activeComplementsCart/${id}`, params);
    return new Promise((resolve, reject) => {
      this._server.postServer("activeComplementsCart/" + id, { session_id: params }).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  pagoManual(datosDePago: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.uploadFile("transfer", datosDePago).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  updateComplementSubscription(id: number, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("subscriptionRealEstateUpdate/" + id, params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  verifiedSubscription(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("verifiedSubscriptionRealEstate/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  verifiedSubscriptionPaginaWeb(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("verifiedSubscriptionPaginaWebRealEstate/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  verifiedComplements(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("verifiedComplementsRealEstate/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  paymentPlanActivate(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("paymentPlanActivate").then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getValuePaymentPlanActivate(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("valuesAssessment").then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  compraInscripcion(id: number, params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("paymentRealEstateProduct", params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  completePaymentRealEstateProduct(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("completePaymentRealEstateProduct", params).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getToken() {
    let token = localStorage.getItem("accessToken");
    this.header = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
  }

  // getPaymentInscriptionRealEstate(id: any): Promise<any> {
  //   /* return new Promise((resolve, reject) => {
  //     this.http.get(environment.API_URL + 'getPaymentInscriptionRealEstate/'+id, { headers: this.header }).subscribe(
  //       (res) => {
  //         resolve(res);
  //       }, (err) => {
  //         reject(err);
  //       }
  //     );
  //   }); */
  //   return new Promise((resolve, reject) => {
  //     this._server.getServer('getPaymentInscriptionRealEstate/' + id).then(
  //       (resp: any) => {
  //         resolve(resp);
  //       }, (error: any) => {
  //         reject(error);
  //       }
  //     );
  //   });
  // }
}
