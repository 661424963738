import { Injectable } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Payment } from './payments.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private _server: ServerService) { }

  getPayments(params?): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Payments', params).then(
        (resp: Payment[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  getPaymentsSubscriptions(data): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      this._server.postServer('getAllSubscriptions', data).then(
        (resp: Payment[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  /* getPaymentsInscriptions(data): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      this._server.postServer('getPayments', data).then(
        (resp: Payment[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  } */

  getPaymentInscriptionRealEstate(params): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      
      this._server.getServer('getPaymentInscriptionRealEstate/'+ params.real_estate_id).then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          // console.log('err servicio Payments', err);
          reject(err);
        }
      );

    });
  }

  /* getClientsByRE(idRE: number): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('indexClientsRE/' + idRE).then(
        (resp: Payment[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  indexPayments(): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('indexPayments').then(
        (resp: Payment[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  } */

  getPayment(id: number): Promise<Payment> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Payments/' + id).then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  storePayment(payment: Payment): Promise<Payment> {
    return new Promise((resolve, reject) => {
      this._server.postServer('Payments', payment).then(
        (resp: Payment) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  updatePayment(payment: Payment): Promise<Payment> {
    return new Promise((resolve, reject) => {
      this._server.putServer('Payments/' + payment.id, payment).then(
        (resp: Payment) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  deletePayment(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.deleteServer('Payments/' + id).then(
        (resp: Payment) => {
          resolve();
        }, (error: any) => {
          reject(error);
        }
      );
    })
  }

  asignaPaymentRealEstate(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.getServer('asignaPaymentRealEstate').then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

}
