import { Injectable, SimpleChanges } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeColorsService {
    
    // userLoggeado = JSON.parse(localStorage.getItem('currentUser'))?.user;
    
    constructor() { }
    
    ngOnChanges(changes: SimpleChanges) {
        /* if(!changes.coleccion.firstChange)  */console.log('DETECTE CAMBIO');
    }
    
    getColorTheme(blue_type?: number) {
        let userLoggeado = JSON.parse(localStorage.getItem('currentUser'))?.user;
        let themeColors = localStorage.getItem('themeColors');

        if(themeColors) {
            if(userLoggeado && userLoggeado?.role == 'Inmobiliaria' && themeColors == 'real_estate'){
                let primaryColor = userLoggeado.primaryColor;
                let secondaryColor = userLoggeado.secondaryColor;
                
                if(userLoggeado.primaryColor == "#000000" && userLoggeado.secondaryColor == "#FFFFFF"){ // Significa que no han modificado los colores
                    if(userLoggeado.primaryColor == "#000000" && blue_type == 1) { primaryColor = '#293145'; } //'#11201E'; }
                    else if(userLoggeado.primaryColor == "#000000" && blue_type == 2) { primaryColor = '115fab'; } ////'#115FAB'; }
                }
                
                return {
                    'background-color': primaryColor + '!important',
                    'background': primaryColor + '!important',
                    'color': secondaryColor + '!important'
                };
            }
        } 
    }
    
}
